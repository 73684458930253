/* header  */

/* text animation */
.Hi {
  animation-delay: 0.4s !important;
}
.Welcome {
  animation-delay: 0.6s !important;
}
.To {
  animation-delay: 0.8s !important;
}

.content-me {
  animation-delay: 1s !important;
}
.heart {
  animation-delay: 1.2s !important;
}
.hr {
  animation-delay: 1.2s !important;
}

/* socail media animation */
.whatsapp {
  animation-delay: 1.4s !important;
}
.linkedIn {
  animation-delay: 1.5s !important;
}
.Instagram {
  animation-delay: 1.6s !important;
}
.Telegram {
  animation-delay: 1.7s !important;
}
.Behance {
  animation-delay: 1.8s !important;
}
.Gmail {
  animation-delay: 1.9s !important;
}
