@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./animation.css";
*,
*:after,
*::before {
  transition-property: opactiy, background, color, drop-shadow, box-shadow,
    transform, border;
  transition-timing-function: linear;
  transition-duration: 0.1s;
}
.sf-x {
  @apply mx-[3px];
}
.sf-y {
  @apply my-2;
}
body {
  content-visibility: auto;
  contain-intrinsic-size: 1px 5000px;
}
.btn {
  @apply px-2 py-1 scr_sm:px-4 scr_sm:py-2 rounded-lg cursor-pointer border-2;
}

.active {
  @apply bg-white  border-light  shadow-lg;
  /* dark mode */
  @apply dark:shadow-md
        dark:bg-black 
        dark:border-yellow 
        dark:text-yellow 
        dark:shadow-yellow;
}

.socail-media-icon:hover .tooltip {
  @apply opacity-100 scale-100;
}
.tooltip {
  @apply bg-light 
  dark:bg-black
    scale-0
    text-dark 
    dark:text-light 
    opacity-0
    text-xs
    px-2 
    py-1 
    text-center 
    rounded-md 
    border-dark 
    dark:border-black 
    border-2
    relative
    z-10
    after:absolute
    after:content-[""]
    after:-bottom-4
    after:-z-50
    after:left-[50%]
    after:translate-x-[-50%]
    after:w-0
    after:h-0
    after:border-[.5rem]
    after:border-[transparent]
    after:border-t-dark
    dark:after:border-t-black;
}
.nav-svg * {
  @apply dark:stroke-yellow;
}
.nav-svg-red * {
  @apply stroke-red;
}
.btn-nav {
  @apply hover:scale-110 hover:font-bold;
}
.input {
  @apply px-3 py-1 text-lg;
}
.close {
  @apply border-red border-2;
}
.close:hover {
  @apply border-black bg-red;
}
.close:hover * {
  @apply stroke-white;
}
.mobile-nav {
  box-shadow: -10px 0 10px #0000001a;
}
#none-animation * {
  transition-duration: 0s !important;
}
